import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import TerminBox from '../components/TerminBox'
import NewsletterSignUp from '../components/NewsletterSignUp'
import { Helmet } from 'react-helmet'

export const MitmachenPageTemplate = ({ title, content, contentComponent, image, termine }) => {
  const PageContent = contentComponent || Content
  return (
    <div>
      <section className="section hero has-overlay section--gradient background--image background--fixed has-text-white level" style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
      }}>
        <div className="container level">
          <div className="section">
            <div className="columns">
              <div className="column ">
                <h1 className="title has-text-white">Lass uns <br></br><b>Bayreuth mitgestalten!</b></h1>
                <div className="buttons is-centered are-medium">
                  <a className="button has-shadow is-primary" href="#mitmachen">Mitmachen</a>
                  <a className="button has-shadow" href="#spenden">Spenden</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="section">
                <h2 className="title is-size-1" id="mitmachen">
                  Willkommen in unserem Team!
                </h2>
                <p className="subtitle">Du bist auch Rad-Enthusiast*in? Und auch noch Jurist*in, Handwerker*in, Fotograf*in, 3D-Artist*in, Journalist*in, Unternehmer*in, Student*in, Elternteil, Schüler*in, irgendwas anderes oder alles gleichzeitig? <b>Wir freuen uns über Deine Expertise!</b></p>
                <p>Bei einem Bürgerbegehren fallen viele Aufgaben an. Das geht über die Planung von öffentlichkeitswirksamen Aktionen, die Regelung von Finanzen, die juristische Ausarbeitung des Forderungspapiers, Pressearbeit und vieles mehr.</p>
                <p>Unsere nächsten Treffen, die "Joint Meetings", in denen der aktuelle Projektfortschritt besprochen wird findest du hier - <b>komm doch einfach mal vorbei.</b></p>
                {termine.map((termin, i) => {
                  return (
                    <TerminBox key={{ i }} termin={termin} />
                  )
                })}
                <div style={{ height: '100px' }}></div>
                <h2 className="title is-size-1">
                  Bleib auf dem Laufenden!
                </h2>
                <h3 class="subtitle">Unser Newsletter</h3>
                <NewsletterSignUp className="" />
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

MitmachenPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const MitmachenPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <MitmachenPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
        termine={post.frontmatter.termine}
      />
      <Helmet titleTemplate="%s | Radentscheid Bayreuth">
        <title>{`${post.frontmatter.title}`}</title>
        <meta
          name="description"
          content={`${post.frontmatter.description}`}
        />
      </Helmet>
    </Layout>
  )
}

MitmachenPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MitmachenPage

export const mitmachenPageQuery = graphql`
  query MitmachenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html 
      frontmatter {
        title
        termine {
          titel
          datum
          ort
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
