import React from 'react'
import moment from 'moment'

const TerminBox = ({ termin }) => {
    return (
        <div className="has-overlay-bg has-text-white card" style={{ padding: '.8em', margin: '1em' }}>
            <div className="column">
                <h1 className="title has-text-white is-4">{termin.titel}</h1>
                <span>{moment(termin.datum).format("DD.MM.YYYY HH:mm")} | Ort: {termin.ort}</span>
            </div>
        </div>
    )

}

export default TerminBox